import { Link } from "gatsby";
import React from "react";
import PaginationPropsTypes from "./types";

export default function Pagination({ numOfPages, currentPageIndex, mainPath }: PaginationPropsTypes) {
  return (
    <nav aria-label="Page pagination nav">
      <ul className="pagination justify-content-center justify-content-lg-end">
        <li className="page-item mx-1">
          <Link
            className="page-link"
            to={`${mainPath}/`}
            aria-label="Previous"
          >
            <span aria-hidden="true">«</span>
          </Link>
        </li>
        {
          Array.from({ length: numOfPages }).map((_, index) => {
            let activeClassName = currentPageIndex === index ? "active" : "";
            return (
              <li
                key={`page-shop-num-${_}-${index}`}
                className={`page-item mx-1 ${activeClassName}`}
              >
                <Link
                  to={`${mainPath}/${index === 0 ? "" : index + 1 + "/"}`}
                  className={`page-link`}
                >
                  {index + 1}
                </Link>
              </li>
            );
          })
        }
        <li className="page-item ms-1">
          <Link
            to={`${mainPath}/${numOfPages == 1 ? "" : numOfPages}/`}
            className="page-link"
            aria-label="Next"
          >
            <span aria-hidden="true">»</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
}
